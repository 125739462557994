import type { ToRaw } from '@monorepo/toolkit-core/types';

// Internal model

export enum PathPartKind {
  Constant = 'constant',
  Parametric = 'parametric',
  Assumed = 'assumed-parametric',
  Wildcard = 'wildcard',
}

export interface ConstantPathPart {
  kind: PathPartKind.Constant;
  value: string;
}

export interface ParametricPathPart {
  kind: PathPartKind.Parametric;
  value: string;
}

export interface AssumedPathPart {
  kind: PathPartKind.Assumed;
  value: string;
}

export interface WildcardPathPart {
  kind: PathPartKind.Wildcard;
  value: '*';
}

export type PathPart =
  | ConstantPathPart
  | ParametricPathPart
  | AssumedPathPart
  | WildcardPathPart;

// DAO
export interface UrlTreeDAO {
  providers: Record<string, UrlTreeProviderDAO>;
}

export interface UrlTreeProviderDAO {
  declared: Record<string, Array<DeclaredPathPartDAO>>;
  underdeclared: Record<string, Array<DeclaredOrAssumedPathPartDAO>>;
}

export type DeclaredPathPartDAO =
  | ConstantPathPartDAO
  | ParametricPathPartDAO
  | WildcardPathPartDAO
  | MappedPathPartDAO;

export type DeclaredOrAssumedPathPartDAO =
  | DeclaredPathPartDAO
  | AssumedPathPartDAO;

export enum PathPartKindDAO {
  Constant = PathPartKind.Constant,
  Parametric = PathPartKind.Parametric,
  Assumed = PathPartKind.Assumed,
  Wildcard = PathPartKind.Wildcard,
  Mapped = 'mapped-parametric',
}
interface BasePathPartDAO {
  id: number;
  index: number;
  value: string;
  createdAt: Date;
  updatedAt: Date;
}

export type ConstantPathPartDAO = BasePathPartDAO & {
  kind: PathPartKindDAO.Constant;
};

export type ParametricPathPartDAO = BasePathPartDAO & {
  kind: PathPartKindDAO.Parametric;
};

export type AssumedPathPartDAO = BasePathPartDAO & {
  kind: PathPartKindDAO.Assumed;
};

export type WildcardPathPartDAO = BasePathPartDAO & {
  kind: PathPartKindDAO.Wildcard;
};

export type MappedPathPartDAO = BasePathPartDAO & {
  kind: PathPartKindDAO.Mapped;
  mapping: PathPartMappingDAO;
};

// Path part mapping

export interface PathPartMappingDAO {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  value: string;
}

export type PrimitiveUrlTreeDAO = ToRaw<UrlTreeDAO>;
export type PrimitiveUrlTreeProviderDAO = ToRaw<UrlTreeProviderDAO>;
export type PrimitivePathPartDAO = ToRaw<
  | ConstantPathPartDAO
  | ParametricPathPartDAO
  | AssumedPathPartDAO
  | WildcardPathPartDAO
  | MappedPathPartDAO
>;
