export interface UserDAO {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  externalUserId: string;
  email: string;
  role: UserRole;
}
export type UserWithStatusDAO = UserDAO & { status: UserStatus };

export enum UserRole {
  Owner = 'owner',
  Admin = 'admin',
  Member = 'member',
}

// When a user is not part of an organization, they would have their
// own API Key(s).
export type UserWithStatusWithApiKeysDAO = UserWithStatusDAO & {
  apiKeys: ApiKeyDAO[];
};

export interface ApiKeyDAO {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  key: string;
}

export enum UserStatus {
  PendingProxyIntegration = 'pending_proxy_integration',
  PendingInterceptorIntegration = 'pending_interceptor_integration',
  Integrated = 'integrated',
}

export interface OrganizationDAO {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  externalName: string;
  members: UserDAO[];
  invitees: InviteeDAO[];
  // If there is an organization, the API Keys belong solely to the organization
  // and never to its users.
  apiKeys: ApiKeyDAO[];
}

export interface InviteeDAO {
  id: string;
  email: string;
  invitationUrl: string;
  invitedAt: Date;
  expiresAt: Date;
}
