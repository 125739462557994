<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  fieldId?: string;
  label?: string;
  required?: boolean;
  inline?: boolean;
}>();

const computedClasses = computed(() => {
  return {
    'form-label--inline': props.inline,
  };
});
</script>

<template>
  <div class="form-label" :class="computedClasses">
    <slot />
    <label :for="fieldId" class="form-label__label"
      ><slot name="label">{{ label }}</slot>
      <sup v-if="required" class="form-label__required">*</sup></label
    >
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.form-label {
  $self: &;

  color: theme('color-text-primary');
  font-size: $font-size-small;
  line-height: $line-height-default;
  font-weight: $font-weight-semibold;

  &__required {
    color: theme('color-fg-danger');
    line-height: 0.8;
  }

  &--inline {
    display: flex;
    gap: $space-xxsmall;
    align-items: center;
  }
}
</style>
