import { useFetch as vueUseFetch } from '@vueuse/core';
import { useUserStore } from '../stores/user';

/**
 * This is a helper function that wraps the useFetch function from vueuse/core
 * automatically adds the Authorization header to the request if the user is authenticated.
 *
 * @param url
 * @param options
 */
const useFetch = (url: string, options: RequestInit = {}) => {
  const root = import.meta.env.VITE_REST_URL + import.meta.env.VITE_REST_PATH;
  const { token } = useUserStore();

  // If the user is authenticated and the request doesn't already have an Authorization header, add it.
  if (token && !(options.headers as Record<string, string>)?.Authorization) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${token.id_token}`,
    };
  }

  // If the url is relative, prepend the root url.
  if (!url.startsWith('http')) {
    url = root + url;
  }

  return vueUseFetch(url, options);
};

export { useFetch };
