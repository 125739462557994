<script lang="ts">
import { cva, type VariantProps } from 'cva';
import { isArray } from 'lodash-es';

export const inputVariants = {
  size: {
    small: 'checkbox--small',
  },
};

const classes = cva({ variants: inputVariants });

export type InputProps = VariantProps<typeof classes>;
</script>

<script setup lang="ts">
import { computed, ref, useAttrs, useSlots } from 'vue';

import BaseIcon from '../BaseIcon/BaseIcon.vue';
import BaseFormField, {
  type BaseFormFieldProps,
} from '../BaseFormField/BaseFormField.vue';

const props = withDefaults(
  defineProps<
    BaseFormFieldProps & {
      /**
       * Input name.
       */
      name?: string;
    }
  >(),
  {
    layout: 'inline',
  }
);

const emit = defineEmits<{
  blur: [evt: Event];
  touched: [value: boolean];
  dirty: [value: boolean];
  click: [];
}>();

const attr = useAttrs();

const model = defineModel();
const isFocused = ref(false);
const slots = useSlots();

const field = ref<HTMLInputElement | null>(null);
const isTouched = ref(false);
const isDirty = ref(false);

const hasInvalidMessage = computed(() => !!slots.invalidMessage);
const hasNote = computed(() => !!slots.default);

const isChecked = computed(() => {
  if (isArray(model.value) && attr.value) {
    return model.value.includes(attr.value);
  }
  return model.value === true;
});

const checkboxClasses = computed(() => ({
  'checkbox__checkbox--checked': isChecked.value,
  'checkbox__checkbox--focused': isFocused.value,
  'checkbox__checkbox--disabled': props.disabled,
}));

const onFocus = () => {
  isFocused.value = true;
};

const onBlur = (evt: Event) => {
  isFocused.value = false;

  emit('blur', evt);
  if (!isTouched.value) {
    isTouched.value = true;
    emit('touched', true);
  }
};

const onInput = (evt: Event) => {
  if (!isDirty.value) {
    emit('dirty', true);
    isDirty.value = true;
  }
};

defineExpose({
  field,
});
</script>

<template>
  <BaseFormField
    :label="label"
    :required="required"
    :invalid="invalid"
    :invalid-message="invalidMessage"
    :validator="validator"
    :disabled="disabled"
    :note="note"
    layout="inline"
  >
    <template #label><slot name="label" /></template>
    <template #default="{ formFieldId, passthroughAttrs }">
      <div class="checkbox">
        <input
          ref="field"
          :id="formFieldId"
          class="checkbox__field"
          :name="name"
          type="checkbox"
          v-model="model"
          v-bind="passthroughAttrs"
          tabindex="0"
          @blur="onBlur"
          @focus="onFocus"
          @click="emit('click')"
          @input="onInput"
        />

        <div class="checkbox__checkbox" :class="checkboxClasses">
          <BaseIcon
            v-if="isChecked"
            class="checkbox__checkbox-icon"
            name="check-lg"
          />
        </div>
      </div>
    </template>

    <template v-if="hasInvalidMessage" #invalidMessage
      ><slot name="invalidMessage"
    /></template>
    <template v-if="hasNote" #note><slot /></template>
  </BaseFormField>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.checkbox {
  $self: &;

  position: relative;

  &__field {
    box-sizing: border-box;
    cursor: pointer;
    height: $scale-xxsmall;
    outline: none;
    position: relative;
    opacity: 0;
    top: $space-xxxxsmall;
    width: $scale-xxsmall;
    z-index: layer('overlap');
  }

  &__checkbox {
    align-items: center;
    background-color: theme('color-bg-interactive');
    border: 1px solid theme('color-border-interactive');
    border-radius: $space-xxxsmall;
    display: flex;
    height: $scale-xxsmall;
    justify-content: center;
    width: $scale-xxsmall;
    left: 0;
    position: absolute;
    top: $space-xxxxsmall;
    z-index: layer('base');

    &:hover {
      background-color: theme('color-bg-interactive-hover');
      border-color: theme('color-border-interactive-hover');
    }

    &[disabled] {
      cursor: default;
    }

    &--checked {
      background-color: theme('color-fg-interactive');
      border-color: theme('color-fg-interactive');

      &:hover {
        border-color: theme('color-fg-interactive-hover');
      }

      #{$self}__checkbox-icon {
        color: theme('color-text-primary-inverted');
      }

      &--disabled {
        background-color: theme('color-bg-interactive');
        border-color: theme('color-border-interactive');
        cursor: default;
      }
    }

    &--disabled {
      background-color: theme('color-bg-info');
      border-color: theme('color-border-primary');
      cursor: default;

      #{$self}__checkbox-icon {
        color: theme('color-text-disabled');
      }
    }

    &--focused:not(#{$self}__checkbox--checked):not(
        #{$self}__checkbox--disabled
      ) {
      border-color: theme('color-border-interactive-hover');
    }
  }

  &__checkbox-icon {
    font-size: $font-size-xsmall;
  }
}
</style>
