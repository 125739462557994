<script setup lang="ts">
import { computed, ref } from 'vue';

import type { PrimitiveAggregatedDiscovery } from '@monorepo/shared-model/src/aggregated-discovery';

import BaseDropdown from '../BaseDropdown/BaseDropdown.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import BaseIcon from '../BaseIcon/BaseIcon.vue';
import BaseMenu from '../BaseMenu/BaseMenu.vue';
import BaseMenuItem from '../BaseMenuItem/BaseMenuItem.vue';
import BaseCheckbox from '../BaseCheckbox/BaseCheckbox.vue';
import BaseInput from '../BaseInput/BaseInput.vue';
import BaseHighlight from '../BaseHighlight/BaseHighlight.vue';

const props = defineProps<{
  consumers: PrimitiveAggregatedDiscovery['consumers'];
  disabled?: boolean;
}>();

const selection = defineModel<string[]>();

const query = ref('');
const dropdown = ref<InstanceType<typeof BaseDropdown>>();
const disableClickOutside = ref(false);

const selectedLabel = computed(() => {
  if (selection.value && selection.value.length === 1) {
    return selection.value[0];
  } else if (
    selection.value &&
    selection.value.length > 1 &&
    selection.value.length !== props.consumers.length
  ) {
    return `${selection.value.length} consumers`;
  }

  return 'All consumers';
});

const filteredConsumers = computed(() => {
  return props.consumers?.filter((consumer) =>
    consumer.tag.toLowerCase().includes(query.value.toLowerCase())
  );
});

const noResults = computed(() => {
  return filteredConsumers.value.length === 0;
});
</script>

<template>
  <BaseDropdown
    ref="dropdown"
    class="dashboard-overview-filter-date-range-dropdown"
    placement="bottom-end"
    :disable-click-outside="disableClickOutside"
    :disabled="disabled"
  >
    <template #trigger>
      <BaseButton variant="secondary" :disabled="disabled"
        >{{ selectedLabel
        }}<template #rightIcon><BaseIcon name="chevron-down" /></template
      ></BaseButton>
    </template>

    <div class="dashboard-overview-filter-date-range-dropdown__search">
      <BaseInput
        type="search"
        placeholder="Search consumers…"
        v-model="query"
      />
    </div>
    <BaseMenu class="dashboard-overview-filter-date-range-dropdown__menu">
      <BaseMenuItem v-if="noResults" compact>No consumers found.</BaseMenuItem>
      <BaseMenuItem
        v-for="(consumer, index) in filteredConsumers"
        :key="index"
        compact
        ><BaseCheckbox v-model="selection" :value="consumer.tag"
          ><template #label
            ><BaseHighlight
              :string="consumer.tag"
              :query="query" /></template></BaseCheckbox
      ></BaseMenuItem>
    </BaseMenu>
  </BaseDropdown>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.dashboard-overview-filter-date-range-dropdown {
  $self: &;

  &__search {
    padding: $space-xsmall $space-xsmall;
  }

  &__menu {
    max-height: 256px;
    overflow-y: auto;
    min-width: 256px;
  }
}
</style>
