<script lang="ts">
import { cva, cx, type VariantProps } from 'cva';

export const inputVariants = {
  size: {
    small: 'input--small',
    compact: 'input--compact',
  },
};

const classes = cva({ variants: inputVariants });

export type InputProps = VariantProps<typeof classes>;
</script>

<script setup lang="ts">
import { computed, ref, useSlots, watch } from 'vue';

import BaseIcon from '../BaseIcon/BaseIcon.vue';
import BaseFormField, {
  type BaseFormFieldProps,
} from '../BaseFormField/BaseFormField.vue';

const props = withDefaults(
  defineProps<
    BaseFormFieldProps & {
      /**
       * Input name.
       */
      name?: string;
      /**
       * Input placeholder text.
       */
      placeholder?: string;
      /**
       * Is the input a number?
       */
      number?: boolean;
      /**
       * Input type
       */
      type?: string;
      /**
       * Input size
       */
      size?: InputProps['size'];
      /**
       * Regular expression for restricted character input
       */
      validCharacters?: RegExp;
    }
  >(),
  {
    type: 'text',
  }
);

const emit = defineEmits<{
  blur: [evt: Event];
  touched: [value: boolean];
  dirty: [value: boolean];
  click: [];
}>();

const model = defineModel();
const slots = useSlots();

const field = ref<HTMLInputElement | null>(null);
const isTouched = ref(false);
const isDirty = ref(false);
const fieldType = ref(props.type);
const showPassword = ref(false);

const hasInvalidMessage = computed(() => !!slots.invalidMessage);
const hasLead = computed(() => !!slots.lead);
const hasNote = computed(() => !!slots.default);
const hasLabel = computed(() => !!slots.label);

const computedClasses = computed(() => ({
  'input--has-lead': hasLead.value,
}));

const onBlur = (evt: Event) => {
  emit('blur', evt);
  if (!isTouched.value) {
    isTouched.value = true;
    emit('touched', true);
  }
};

const onClear = () => {
  model.value = '';
  field.value?.focus();
};

const onInput = (evt: Event) => {
  let value: string | number = (evt.target as HTMLInputElement).value;

  if (!isNaN(parseFloat(value)) && props.number) {
    model.value = Number(value);
  }

  if (!isDirty.value) {
    emit('dirty', true);
    isDirty.value = true;
  }
};

const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
  fieldType.value = showPassword.value ? 'text' : 'password';
};

// If present, replace any character that does not match validCharacters with an empty string
watch(model, (value) => {
  if (!props.validCharacters) return;

  const validValue = String(value)
    .split('')
    .filter(
      (char) => !props.validCharacters || props.validCharacters.test(char)
    )
    .join('');

  if (validValue !== value) {
    model.value = validValue;
  }
});

defineExpose({
  field,
});
</script>

<template>
  <BaseFormField
    :label="label"
    :required="required"
    :invalid="invalid"
    :invalid-message="invalidMessage"
    :validator="validator"
    :disabled="disabled"
    :note="note"
  >
    <template v-if="hasLabel" #label><slot name="label" /></template>
    <template #default="{ formFieldId, passthroughAttrs }">
      <div class="input" :class="cx(classes({ size }), computedClasses)">
        <span v-if="type === 'search'" class="input__icon" aria-hidden="true">
          <BaseIcon name="search" />
        </span>
        <span v-if="hasLead" class="input__lead" aria-hidden="true">
          <slot name="lead" />
        </span>
        <input
          :id="formFieldId"
          ref="field"
          class="input__field"
          :name="name"
          :placeholder="placeholder"
          v-bind="passthroughAttrs"
          :type="fieldType"
          v-model="model"
          @blur="onBlur"
          @click="emit('click')"
          @input="onInput"
        />
        <button
          v-if="type === 'search' && !!modelValue"
          type="button"
          class="input__clear"
          aria-label="Clear input"
          :aria-controls="formFieldId"
          @click.stop="onClear"
        >
          <BaseIcon name="close" aria-hidden="true" />
        </button>
        <span
          v-if="type === 'password'"
          class="input__show"
          @click="toggleShowPassword"
        >
          <BaseIcon
            :name="showPassword ? 'eye-cross-outline' : 'eye-outline'"
          />
        </span>
      </div>
    </template>

    <template v-if="hasInvalidMessage" #invalidMessage
      ><slot name="invalidMessage"
    /></template>
    <template v-if="hasNote" #note><slot /></template>
  </BaseFormField>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.input {
  $self: &;

  position: relative;
  width: 100%;

  &__container {
    position: relative;
    width: 100%;
    z-index: layer('base');
  }

  &__icon,
  &__show {
    color: theme('color-text-secondary');
    height: $font-size-medium;
    font-size: $font-size-medium;
    line-height: $font-size-small;
    left: $space-xxsmall;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    width: $font-size-medium;
    z-index: layer('overlap');
  }

  &__lead {
    display: block;
    font-size: $font-size-small;
    line-height: $font-size-small;
    left: $space-xxsmall;
    height: $font-size-small;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    z-index: layer('overlap');
  }

  &__show {
    pointer-events: inherit;
    cursor: pointer;
    left: auto;
    right: $space-xsmall;
    z-index: layer('overlap');
  }

  &__field {
    appearance: none;
    background-color: theme('color-bg-interactive');
    border: 1px solid theme('color-border-interactive');
    color: theme('color-text-primary');
    cursor: text;
    border-radius: $space-xxxsmall;
    box-sizing: border-box;
    font-size: $font-size-small;
    line-height: calc($line-height-default - 2px);
    outline: none;
    padding: $space-xxxsmall $space-xsmall;
    position: relative;
    width: 100%;
    z-index: layer('base');

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      appearance: none;
    }

    &::placeholder {
      color: theme('color-text-secondary');
    }

    &:hover,
    &:focus {
      background-color: theme('color-bg-interactive-hover');
      border-color: theme('color-border-interactive-hover');
    }

    &[type='search'] {
      text-indent: calc($space-small) + 2px;
    }
  }

  &__clear {
    background-color: transparent;
    border: none;
    color: theme('color-text-secondary');
    height: $font-size-small;
    font-size: $font-size-small;
    line-height: $font-size-small;
    padding: 0;
    position: absolute;
    right: $space-xxsmall;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    width: calc($font-size-small + $space-xxsmall);
    z-index: 10;

    &:hover {
      cursor: pointer;
      color: theme('color-text-primary');
    }
  }

  &--small {
    #{$self}__field {
      padding: $space-xxxsmall $space-xxsmall;

      &[type='search'] {
        text-indent: calc($space-medium) + 2px;
      }
    }

    #{$self}__icon {
      left: $space-xxsmall;
    }

    #{$self}__clear {
      width: calc($font-size-xxsmall + $space-xxsmall);
    }
  }

  &--compact {
    #{$self}__field {
      padding: $space-xxxxsmall $space-xxxsmall;
    }
  }

  &--has-lead {
    #{$self}__field {
      padding-left: $space-medium;
    }
  }
}
</style>
